import Swal from 'sweetalert2';

export default {
  loading: (options) => {
    Swal.close();
    Swal.fire({
      icon: 'info',
      title: options.title,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    Swal.showLoading();
  },
  success: (options) => {
    Swal.close();
    Swal.fire({
      icon: 'success',
      title: options.title,
      showConfirmButton: false,
    });
    setTimeout(() => {
      Swal.close();
    }, 1500);
  },
  error: (options) => {
    Swal.close();
    Swal.fire({
      icon: 'error',
      title: options.title,
      text: options.text,
      confirmButtonText: 'Got it!',
    });
  },
};
