import EN_translations from './translation_files/EN_translations.json';
import NL_translations from './translation_files/NL_translations.json';
import FR_translations from './translation_files/FR_translations.json';
import DE_translations from './translation_files/DE_translations.json';
import IT_translations from './translation_files/IT_translations.json';

export const translate = (app, label, lang) => {
  switch (lang) {
    case 'en':
      return EN_translations[app][label];
    case 'nl':
      return NL_translations[app][label];
    case 'fr':
      return FR_translations[app][label];
    case 'de':
      return DE_translations[app][label];
    case 'it':
      return IT_translations[app][label];
    default:
      return EN_translations[app][label];
  }
};
